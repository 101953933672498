import * as React from "react";
import { css, useTheme } from "@fckdtl/themotion/react";

import Grid from "./layout/Grid";
import Row from "./layout/Row";
import Cell from "./layout/Cell";
import { Overline, H1, Subtitle1 } from "./texts";

const PageHeader = ({ overline, headline, subline }) => {
  const theme = useTheme();
  return (
    <Grid
      css={css(`
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;
    min-height: {352, 352,,,,664}({"step": 8, "method": "round"})px;
    `)(theme)}
      className="py-14"
    >
      <Row>
        <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
          {!!overline && <Overline>{overline}</Overline>}
          <H1 className="pt-1">{headline}</H1>
          {!!subline && (
            <Subtitle1
              className="pt-4"
              css={css(`color: var(--color-spot1);`)(theme)}
            >
              {subline}
            </Subtitle1>
          )}
        </Cell>
      </Row>
    </Grid>
  );
};

export default PageHeader;
